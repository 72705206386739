import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

class Gallery extends Component {

  render() {
    let { gallery } = this.props

    const settings = {
      infinite: true,
      speed: 600,
      fade:true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
    }
    return (
      <section className="image gallery">
        <div className="image__inner">
          <Fade bottom distance="30px">
            { gallery && 
              <Slider className="gallery" {...settings}>
                { gallery.map((el, i) => {
                  return ( 
                    <div key={i}>
                      <img src={el.image.localFile.childImageSharp.original.src} alt={el.caption} /> 
                      <span className="image__caption">{el.caption}</span>
                    </div>
                  )
                }) }
              </Slider>
            }
          </Fade>
        </div>
      </section>
    )
  }
}

export default Gallery
