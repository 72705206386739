import pageLinksData from '../data/pageLinksData'

const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    var A = a[key],
      B = b[key]
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

const removeSlashes = string => string.replace(/\//g, '')

//Refer to /data/pageLinksData
const getPageLinks = ({ pathname }) => {
  return mapOrder(
    pageLinksData,
    pageLinksData.find(
      link => removeSlashes(link.link) == removeSlashes(pathname)
    )
      ? pageLinksData.find(
          link => removeSlashes(link.link) == removeSlashes(pathname)
        ).order
      : pageLinksData.find(link => link.name == 'Work').order,
    'name'
  )
}

export default getPageLinks
