import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Video from './video'

class DoubleContent extends Component {

  render() {
    let { left_video_id, left_image, left_image_caption, right_video_id, right_image, right_image_caption } = this.props
    let leftVideoProps = {
      video: left_video_id,
      poster: left_image.localFile.childImageSharp.original.src,
      autoPlay: true,
    }
    let rightVideoProps = {
      video: right_video_id,
      poster: right_image.localFile.childImageSharp.original.src,
      autoPlay: true,
    }
    return (
      <section className="double-content">
        <div className="double-content__inner">
          <Fade bottom distance="30px">
            <div className='double-content__left'>
              <div className="video__wrapper">
                <Video {...leftVideoProps} />
              </div>
              { left_image_caption &&
                <span className="image__caption">{left_image_caption}</span>
              }
            </div>
            <div className='double-content__right'>
              <div className="video__wrapper">
                <Video {...rightVideoProps} />
              </div>
              { right_image_caption && 
                <span className="image__caption">{right_image_caption}</span>
              }
            </div>
          </Fade>
        </div>
      </section>
    )
  }
}

export default DoubleContent
