import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Image extends Component {

  render() {
    let { image, caption } = this.props
    return (
      <section className="image">
        <div className="image__inner">
          <Fade bottom distance="30px">
            { image && <img src={image.localFile.childImageSharp.original.src} alt={caption} /> }
            <span className="image__caption">{caption}</span>
          </Fade>
        </div>
      </section>
    )
  }
}

export default Image
