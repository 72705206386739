import { TimelineMax, Power1 } from 'gsap'

const homeAnimationInit = node => {
  const tl = new TimelineMax()
  tl.to(
    node
      .querySelector('.main')
      .querySelectorAll('.intro__top, .intro__content, .intro__bottom'),
    0,
    { opacity: 0 },
    0
  )
}

const homeAnimationShow = async node => {
  return new Promise(resolve => {
    const tl = new TimelineMax({
      onComplete: () => {
        resolve(true)
      },
    })
    tl.staggerFromTo(
      node
        .querySelector('.main')
        .querySelectorAll('.intro__top, .intro__content, .intro__bottom'),
      0.3,
      { y: -20, opacity: 0 },
      { y: 0, opacity: 1 },
      0.2
    )
  })
}

const startDesktopAnimationInit = async node => {
  return new Promise(resolve => {
    const delay = 2

    Array.from(node.querySelectorAll('.slide')).map((el, i) => {
      el.style.opacity = 1
      const tl = new TimelineMax({ delay: i * delay })
      tl.from(el, 1, { width: '0%' }, 1)
      tl.fromTo(
        el.querySelector('.slide-tag'),
        1,
        { opacity: 0, y: '+=-20' },
        { opacity: 1, y: '0' },
        2
      )
      tl.fromTo(
        el.querySelector('.slide-image'),
        4,
        { scale: 1.05 },
        { scale: 1 },
        1
      )
    })

    const tle = new TimelineMax({
      delay: node.querySelectorAll('.slide').length * delay,
      onComplete: () => {
        resolve(true)
      },
    })

    tle
      .fromTo(node, 1, { opacity: 1 }, { opacity: 0 }, 1)
      .to(node, 0.1, { display: 'none' }, 2)
  })
}

const startMobileAnimationInit = async node => {
  return new Promise(resolve => {
    const delay = 2

    Array.from(node.querySelectorAll('.slide')).map((el, i) => {
      el.style.opacity = 1
      const tl = new TimelineMax({ delay: i * delay })
      tl.fromTo(el, 1, { height: '0%' }, { height: '100%' }, 1)
      tl.fromTo(
        el.querySelector('.slide-tag'),
        1,
        { opacity: 0, y: '+=-20' },
        { opacity: 1, y: '0' },
        2
      )
      tl.fromTo(
        el.querySelector('.slide-image'),
        4,
        { scale: 1.05 },
        { scale: 1 },
        1
      )
    })

    const tle = new TimelineMax({
      delay: node.querySelectorAll('.slide').length * delay,
      onComplete: () => {
        resolve(true)
      },
    })

    tle
      .fromTo(node, 1, { opacity: 1 }, { opacity: 0 }, 1)
      .to(node, 0.1, { display: 'none' }, 2)
  })
}

const pageOutMobile = (e, entry, node, index) => {
  const tl = new TimelineMax()

  tl.staggerFromTo(
    node.querySelectorAll('.main'),
    1,
    { height: '100%' },
    { height: '0%' }
  )

  tl.to(
    node.querySelectorAll('.page-link'),
    0,
    {
      background: 'transparent',
    },
    0
  )

  tl.to(
    node.querySelectorAll('.page-link span'),
    0,
    {
      color: 'transparent',
    },
    0
  )

  if (index == 1) {
    tl.to(
      node.querySelectorAll('.page-links'),
      1,
      {
        transform: 'translateY(-50px)',
      },
      0
    )
  } else {
    tl.to(
      node.querySelectorAll('.page-links'),
      1,
      { transform: 'translateY(-100px)' },
      0
    )
  }

  return tl
}

const pageOutDesktop = (e, entry, node, index) => {
  const tl = new TimelineMax()

  tl.staggerFromTo(
    node.querySelectorAll('.main'),
    1,
    { width: '100%' },
    { width: '0%' }
  )

  tl.to(
    node.querySelectorAll('.page-link'),
    0,
    {
      background: 'transparent',
    },
    0
  )

  tl.to(
    node.querySelectorAll('.page-link span'),
    0,
    {
      color: 'transparent',
    },
    0
  )

  if (index == 1) {
    tl.to(
      node.querySelectorAll('.page-links'),
      1,
      {
        transform: 'translateX(-50px)',
      },
      0
    )
  } else {
    tl.to(
      node.querySelectorAll('.page-links'),
      1,
      { transform: 'translateX(-100px)' },
      0
    )
  }

  return tl
}

const pageInMobile = (e, entry, node, index) => {
  const tl = new TimelineMax()

  if (index == 1) {
    tl.from(
      node.querySelectorAll('.page-links'),
      1,
      { transform: 'translateY(50px)' },
      0
    )
  } else {
    tl.from(
      node.querySelectorAll('.page-links'),
      1,
      { transform: 'translateY(100px)' },
      0
    )
  }

  return tl
}

const pageInDesktop = (e, entry, node, index) => {
  const tl = new TimelineMax()

  if (index == 1) {
    tl.from(
      node.querySelectorAll('.page-links'),
      1,
      { transform: 'translateX(50px)' },
      0
    )
  } else {
    tl.from(
      node.querySelectorAll('.page-links'),
      1,
      { transform: 'translateX(100px)' },
      0
    )
  }

  return tl
}

export {
  pageOutDesktop,
  pageInDesktop,
  pageOutMobile,
  pageInMobile,
  startDesktopAnimationInit,
  startMobileAnimationInit,
  homeAnimationInit,
  homeAnimationShow,
}
