import React, { Component } from 'react'
import axios from 'axios'

class Video extends Component {

  state = {
    video: null,
  }

  componentDidMount() {
    this.props.video && axios(`https://api.codedrips.com/youtube/${ this.props.video }`)
      .then((response) => {
        let { data } = response
        this.setState({ video: data })
      })
      .catch((e) => console.log(e))
  }

  _play() {
    let { video } = this.refs
    if (video && video.readyState === 4) {
      video.play()
    }
  }

  _rewind() {
    let { video } = this.refs;
    if (!video) return
    video.pause()
    video.currentTime = 0
    video.load()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.play !== this.props.play) {
      if (this.props.play) {
        this._play()
      } else {
        this._rewind()
      }
    }
  }

  render() {
    let { video } = this.state
    let { poster, autoPlay } = this.props

    return <video ref='video' src={video && video.url} poster={poster} autoPlay={autoPlay ? 'autoplay' : false} muted={'muted'} playsInline={'playsinline'} loop={'loop'} />
  }

}

export default Video