import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Video from './video'

class VideoContent extends Component {

  render() {
    let { video, poster } = this.props
    let videoProps = {
      video: video,
      poster: poster.localFile.childImageSharp.original.src,
      autoPlay: true,
    }
    return (
      <section className="video">
        <Fade bottom distance="30px">
          { video && 
            <div className='video__wrapper'>
              <Video {...videoProps} />
            </div>
          }
        </Fade>
      </section>
    )
  }
}

export default VideoContent
