import React, { useState, useEffect } from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import getLinkId from '../utils/getLinkId'
import getPageLinks from '../utils/getPageLinks'
import {
  pageOutDesktop,
  pageInDesktop,
  pageOutMobile,
  pageInMobile,
} from '../animation/timelines'

const PageSwitch = ({ location }) => {
  const windowWidth = typeof window !== 'undefined' && window.innerWidth > 1000
  return (
    <div className="page-links-container">
      <div className="page-links">
        {getPageLinks(location).map((link, i) => {
          return (
            <TransitionLink
              id={`tlink-${i}`}
              key={link.name}
              className={`page-link page-link-${link.name.toLowerCase()}`}
              to={link.link}
              exit={
                windowWidth
                  ? {
                      length: 1,
                      zIndex: 2,
                      trigger: ({ e, entry, node }) => {
                        const index = getLinkId(e.target.id)
                        pageOutDesktop(e, entry, node, index)
                      },
                    }
                  : {
                      length: 1,
                      zIndex: 2,
                      trigger: ({ e, entry, node }) => {
                        const index = getLinkId(e.target.id)
                        pageOutMobile(e, entry, node, index)
                      },
                    }
              }
              entry={
                windowWidth
                  ? {
                      length: 1,
                      zIndex: 1,
                      trigger: ({ e, enty, node }) => {
                        const index = getLinkId(e.target.id)
                        pageInDesktop(e, enty, node, index)
                      },
                    }
                  : {
                      length: 1,
                      zIndex: 1,
                      trigger: ({ e, enty, node }) => {
                        const index = getLinkId(e.target.id)
                        pageInMobile(e, enty, node, index)
                      },
                    }
              }
            >
              <span>{link.name}</span>
            </TransitionLink>
          )
        })}
      </div>
    </div>
  )
}

export default PageSwitch
