import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class BannerImage extends Component {

  render() {
    let { image } = this.props
    return (
      <section className="banner-image">
        <Fade bottom distance="30px">
          { image && <img src={image.localFile.childImageSharp.original.src} alt="Cam Richards" /> }
        </Fade>
      </section>
    )
  }
}

export default BannerImage
