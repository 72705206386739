import React from 'react'

import BannerImage from '../components/banner-image'
import Content from '../components/content'
import Image from '../components/image'
import Gallery from '../components/gallery'
import VideoContent from '../components/video-content'
import DoubleContent from '../components/double-content'
import DoubleImage from '../components/double-image'

const renderBlock = (param, el, i, page={}) => {
  let block = {
    'WordPressAcf_banner_image': (el, i) => (<BannerImage {...el} key={i} />),
    'WordPressAcf_content': (el, i) => (<Content {...el} key={i} />),
    'WordPressAcf_image': (el, i) => (<Image {...el} key={i} />),
    'WordPressAcf_gallery': (el, i) => (<Gallery {...el} key={i} />),
    'WordPressAcf_banner_video': (el, i) => (<VideoContent {...el} key={i} />),
    'WordPressAcf_double_content': (el, i) => (<DoubleContent {...el} key={i} />),
    'WordPressAcf_double_image': (el, i) => (<DoubleImage {...el} key={i} />),
  }[param]

  if (!block) return

  return block(el, i)
}

export default renderBlock



