import React, { Component } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import RenderBlock from '../utils/render-block'
import PageSwitch from '../components/pageSwitch'

import SEO from '../components/seo'
import workArrow from '../assets/images/projects-arrow.svg'
import downArrow from '../assets/images/down-arrow.svg'

class ProjectContent extends Component {

  state = {
    password_protected: this.props.project.acf.password_protected,
    password: ''
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    if ( this.state.password === 'seemyproject' ) {
      this.setState({ password_protected: false })
    }
  }

  _nextProject()  {
    const currentProject = this.props.project
    // Find next project
    const projects = this.props.projects.edges
    let last = 0
    let next = projects.find(el => {
      if (currentProject.id === last) return true
      last = el.node.id
      return false
    })

    if (!next) next = projects[0]

    let props = {
      to: `/${ next.node.slug }`,
      onClick: (event) => {
        event.preventDefault()
        // transition up 
        let target = event.currentTarget
        navigate(target.getAttribute('href'))
      },
    }
    return ( 
      <section className='content content--next'>
        <Fade bottom distance="30px">
          <div className="content__inner">
            <p>Next Project</p>
            <h2><Link {...props}>{ next.node.title }</Link></h2>
          </div>
        </Fade>
      </section>
    )
  }

  render() {
    let { project } = this.props
    let { password_protected } = this.state
    let props = {
      name: 'password-form',
      ref: 'form',
      onSubmit: this.handleSubmit
    }
    return (
      <>
        <section className="work__header project__header">
          <Link to="/work">
            <img src={workArrow} alt="Cam Richards - Back Home" />
            <span>Projects</span>
          </Link>
          <a className="git" href="mailto:richards.cameron68@gmail.com">
            Get In Touch
          </a>
        </section>
        { password_protected &&
          <section className="work__password">
            <h2>Sorry but this project is password protected.</h2>
            <p>To view please enter the password:</p>
            <form {...props}>
              <input name="password" type="password" placeholder="Enter password" onChange={this.handleChange} />
              <button type="submit">→</button>
            </form>
            <span>
              If you don’t have it, send me a message and I’ll sort you out. <a href="mailto:richards.cameron68@gmail.com">Get in touch</a>
            </span>
          </section>
        }
        { !password_protected &&
          <section className="work__wrapper project__wrapper">
            <div className="project__header">
              <h1>{project.title}</h1>
              <div
                className="project__excerpt"
                dangerouslySetInnerHTML={{ __html: project.acf.excerpt }}
              />
              <span className="project__role">
                <strong>Role:</strong>
                <br />
                {project.acf.role}
              </span>
            </div>
            { project.acf.content_blocks_case_study &&
              project.acf.content_blocks_case_study.map((el, i) => {
                return RenderBlock(el.__typename, el, i)
              })
            }
            <section className='content content--team'>
              <Fade bottom distance="30px">
                <div className="content__inner">
                  { project.acf.primary_roles && 
                    <div className="content__pr">
                      <h5>Primary roles</h5>
                      <ul>
                        { project.acf.primary_roles && project.acf.primary_roles.map((el, i) => {
                          return (
                            <li key={i}>
                              { el.text }
                            </li>
                          )
                        }) }
                      </ul>
                    </div>
                  }
                  { project.acf.team && 
                    <div className="content__team">
                      <h5>Team</h5>
                      <ul>
                        { project.acf.team && project.acf.team.map((el, i) => {
                          return (
                            <li key={i}>
                              { el.text }
                            </li>
                          )
                        }) }
                      </ul>
                    </div>
                  }
                </div>
              </Fade>
            </section>
            { this._nextProject() }
          </section>
        }
      </>
    )
  }
}

class ProjectTemplate extends Component {

  render() {
    let {
      data: { project, projects },
      location,
    } = this.props

    return (
      <>
        <SEO
          title={he.decode(project.yoast_meta.yoast_wpseo_title)}
          description={project.yoast_meta.yoast_wpseo_metadesc}
          bodyClass="project"
        />
        <div className="main-wrap main-wrap-work">
          <div className="page-main-work main">
            <div>
              <ProjectContent project={project} projects={projects} />
            </div>
          </div>
          <PageSwitch location={location} />
        </div>
      </>
    )
  }
}

export const projectQuery = graphql`
  query($id: String!) {
    projects: allWordpressWpCaseStudy(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    project: wordpressWpCaseStudy(id: { eq: $id }) {
      id
      title
      slug
      date
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        excerpt
        role
        featured_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        primary_roles {
          text
        }
        team {
          text
        }
        password_protected
        content_blocks_case_study {
          __typename
          ... on WordPressAcf_content {
            content
            large_first_paragraph
          }
          ... on WordPressAcf_banner_image {
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_image {
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            caption
          }
          ... on WordPressAcf_banner_video {
            video
            poster {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_gallery {
            gallery {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              caption
            }
          }
          ... on WordPressAcf_double_content {
            left_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            left_image_caption
            left_video_id
            right_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            right_image_caption
            right_video_id
          }
          ... on WordPressAcf_double_image {
            left_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            left_image_caption
            right_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            right_image_caption
          }
        }
      }
    }
  }
`

export default ProjectTemplate
