import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Content extends Component {

  render() {
    let { content, large_first_paragraph } = this.props
    return (
      <section className={ large_first_paragraph ? 'content content--large' : 'content' }>
        <Fade bottom distance="30px">
          <div className="content__inner">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </Fade>
      </section>
    )
  }
}

export default Content