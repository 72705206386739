const pageLinksData = [
  {
    name: 'Home',
    link: '/',
    order: ['Home', 'About', 'Work'],
  },
  {
    name: 'Work',
    link: '/work',
    order: ['Work', 'Home', 'About'],
  },
  {
    name: 'About',
    link: '/about',
    order: ['About', 'Work', 'Home'],
  },
]

export default pageLinksData
