import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class DoubleImage extends Component {

  render() {
    let { left_image, left_image_caption, right_image, right_image_caption } = this.props
    return (
      <section className="double-image">
        <div className="double-image__inner">
          <Fade bottom distance="30px">
            <div className='double-image__left'>
              <img src={left_image.localFile.childImageSharp.original.src} alt={left_image_caption} />
              { left_image_caption &&
                <span className="image__caption">{left_image_caption}</span>
              }
            </div>
            <div className='double-image__right'>
              <img src={right_image.localFile.childImageSharp.original.src} alt={right_image_caption} />
              { right_image_caption &&
                <span className="image__caption">{right_image_caption}</span>
              }
            </div>
          </Fade>
        </div>
      </section>
    )
  }
}

export default DoubleImage
